<template>
  <div class="h5content">
    <div class="hpage1">
      <img class="h5leftimg" src="../assets/left.jpg" />
      <img class="h5rightimg" src="../assets/right.jpg" />
      <div class="card">
        <div class="page1title">
          <div>CONVERGENCE</div>
        </div>
        <div class="page1content">AI-Enabled Trading Algorithm Platform</div>
        <div class="follow">
          <div class="followText">Follow us</div>
          <img
            class="followimg"
            src="../assets/twitter.png"
            width="20"
            height="20"
            @click="gotoTarget('1')"
          />
          <img
            class="followimg"
            @click="gotoTarget('2')"
            src="../assets/M.svg"
            width="20"
            height="20"
          />
          <img
            class="followimg"
            src="../assets/TG.svg"
            width="20"
            height="20"
            @click="gotoTarget('3')"
          />
          <img
            class="followimg"
            src="../assets/Discord.svg"
            width="20"
            height="20"
            @click="gotoTarget('4')"
          />
        </div>
      </div>
    </div>
    <div class="hpage2">
      <img class="h5leftimgpage2" src="../assets/left.jpg" />
      <img class="h5rightimgpage2" src="../assets/right.jpg" />
      <div class="h5page2text">
        <span>Convergence leverages</span>
        <span style="background-color: #fbd784; color: black">advanced AI</span>
        <span>to</span>
        <span style="background-color: #fbd784; color: black"
          >revolutionize trading algorithms,</span
        >
        <span
          >offering a seamless and secure solution for traders seeking to
          maximize profitability while protecting proprietary strategies.</span
        >
      </div>
      <div class="h5line-with-shadows">
        <div class="h5leftCircle"></div>
        <div class="h5top-shadow"></div>
        <div class="h5dashed-line"></div>
        <div class="h5bottom-shadow"></div>
        <div class="h5rightCircle"></div>
      </div>
    </div>
    <div class="hpage4">
      <div style="position: relative">
        <div class="h5Noleft">01</div>
        <div class="page4title">PROBLEM STATEMENT</div>
      </div>
      <div class="h5page4content">
        <div>
          <div class="itemContent" style="margin-top: 16px">
            Trading, fundamentally a matter of buying low and selling high,
            poses intricate challenges:
          </div>
          <div class="itemTitle">Entry and Exit Points</div>
          <div class="itemContent">
            Determining optimal buy and sell points.
          </div>
          <div class="itemTitle">Volume Decisions</div>
          <div class="itemContent">
            Deciding how much to trade at each point.
          </div>
          <div class="itemTitle">AI Integration</div>
          <div class="itemContent">
            Incorporating AI into trading environments without compromising
            security or efficiency.
          </div>
        </div>
        <div class="h5page5imgBox">
          <img class="hpage3Img1" src="../assets/01top.png" alt="" />
          <img class="hpage3Img2" src="../assets/01bottom.png" alt="" />
        </div>
      </div>
    </div>
    <div class="hpage4">
      <div style="position: relative">
        <div class="h5Noleft">02</div>
        <div class="page4title">CAPABILITIES</div>
      </div>
      <div class="h5page4content">
        <div>
          <div class="itemTitle">Algorithm Security</div>
          <div class="itemContent">
            Proprietary algorithms operate within a black-box environment,
            safeguarding the author’s strategies.
          </div>
          <div class="itemTitle">Dynamic Strategy Updates</div>
          <div class="itemContent">
            Continuous adaptation to the latest market conditions, ensuring
            strategies remain effective.
          </div>
          <div class="itemTitle">Real-Time Feedback</div>
          <div class="itemContent">
            AI delivers rapid responses to market fluctuations, enhancing
            decision-making.
          </div>
        </div>
        <div class="h5page5imgBox">
          <img class="hpage4Img1" src="../assets/02top.png" alt="" />
          <img class="hpage4Img2" src="../assets/02bottom.png" alt="" />
        </div>
      </div>
    </div>
    <div class="hpage4">
      <div style="position: relative">
        <div class="h5Noleft">03</div>
        <div class="page4title">CHALLENGES</div>
      </div>
      <div class="h5page4content">
        <div>
          <div class="itemTitle">Tool Limitations</div>
          <div class="itemContent">
            Current market tools often simplify the programming process but lack
            sophistication in guiding trading decisions.
          </div>
          <div class="itemTitle">Strategy Confidentiality</div>
          <div class="itemContent">
            Expert traders are reluctant to share their high-performance
            algorithms to maintain competitive advantage.
          </div>
          <div class="itemTitle">Retail Demand</div>
          <div class="itemContent">
            Retail investors seek low-risk, high-return products but face
            limited options.
          </div>
        </div>
        <div class="h5page5imgBox">
          <img class="hpage6Img1" src="../assets/03top.png" alt="" />
          <img class="hpage6Img2" src="../assets/03bottom.png" alt="" />
          <img class="hpage6Img3" src="../assets/03middle.png" alt="" />
        </div>
      </div>
    </div>
    <div class="hpage4">
      <div style="position: relative">
        <div class="h5Noleft">04</div>
        <div class="page4title">SOLUTIONS</div>
      </div>
      <div class="h5page4content">
        <div>
          <div class="itemTitle">Advanced Algorithms</div>
          <div class="itemContent">
            Built with world-class trading algorithms, Convergence ensures
            superior performance.
          </div>
          <div class="itemTitle">AI-Driven Optimization</div>
          <div class="itemContent">
            AI fine-tunes trading algorithms for maximum profit.
          </div>
          <div class="itemTitle">Secure Execution</div>
          <div class="itemContent">
            Fully Homomorphic Encryption (FHE) technology guarantees the
            confidentiality of trading bot code, preventing leaks.
          </div>
          <div class="itemTitle">User-Friendly Returns</div>
          <div class="itemContent">
            Provides users with one-click access to optimized trading returns.
          </div>
        </div>
        <div class="h5page5imgBox" style="padding-top: 20px">
          <img class="h5page7Img1" src="../assets/04top1.png" alt="" />
          <img class="h5page7Img2" src="../assets/04top2.png" alt="" />
          <img class="h5page7Img3" src="../assets/04top3.png" alt="" />
          <img class="h5page7Img4" src="../assets/04top4.png" alt="" />
          <img class="h5page7Img5" src="../assets/04bottom.png" alt="" />
        </div>
      </div>
    </div>
    <div class="hpage7">
      <div class="hpage7Text">
        Convergence transforms the trading landscape by combining cutting-edge
        AI with robust security, delivering unparalleled trading efficiency and
        profitability.
      </div>
      <div></div>
      <div class="copy">
        <img style="margin-bottom: 5px" src="../assets/copy.png" alt="" />
        <div class="copytext">AI-Enabled Trading Algorithm Platform</div>
        <div class="copytime">
          {{
            `Copyright ${new Date().getFullYear()} Convergence, Inc. Terms & Privacy`
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5Content",
  mounted() {},
  methods: {
    gotoTarget(type) {
      if (type === "1") {
        window.open("https://x.com/ConvergenceFin", "_blank");
      } else if (type === "2") {
        window.open("https://convergencefinance.medium.com/", "_blank");
      } else if (type === "3") {
        window.open("https://t.me/convergencefinanceofficial", "_blank");
      } else if (type === "4") {
        window.open("https://discord.com/invite/G4s5QPEp2y", "_blank");
      }
    },
  },
};
</script>

<style scoped>
.copytext {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 5px;
}
.copytime {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
}
.hpage7Text {
  margin-left: 16px;
  margin-right: 16px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
.hpage7 {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.copy {
  position: absolute;
  bottom: 20px;
  text-align: center;
}
.h5page7Img1 {
  height: auto;
  width: 10%;
  position: absolute;
  top: 25%;
  left: 25%;
}
.h5page7Img2 {
  height: auto;
  width: 10%;
  position: absolute;
  top: 10%;
  left: 36%;
}
.h5page7Img3 {
  height: auto;
  width: 10%;
  position: absolute;
  top: 10%;
  right: 36%;
}
.h5page7Img4 {
  height: auto;
  width: 10%;
  position: absolute;
  top: 25%;
  right: 25%;
}
.h5page7Img5 {
  height: auto;
  width: 60%;
  margin-top: 20%;
}

.hpage6Img1 {
  height: auto;
  width: 60%;
  margin-top: -15%;
  margin-left: -2%;
}
.hpage6Img2 {
  height: auto;
  width: 60%;
  position: absolute;
  right: 10%;
  bottom: 15%;
}
.hpage6Img3 {
  height: auto;
  width: 10%;
  margin-right: 40px;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}
.h5page5imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}

.hpage3Img1 {
  height: auto;
  width: 60%;
}
.hpage3Img2 {
  height: auto;
  width: 60%;
}
.h5page4imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hpage4Img1 {
  height: auto;
  width: 30%;
}
.hpage4Img2 {
  height: auto;
  width: 60%;
  margin-top: -17%;
}
.itemContent {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  font-family: "Inter";
  margin-bottom: 20px;
}
.itemTitle {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  margin-top: 16px;
}
.h5page4content {
  padding-left: 16px;
  padding-right: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: 89vh;
  position: absolute;
  top: 11%;
}
.h5Noleft {
  font-weight: 700;
  font-size: 120px;
  font-family: "Inter";
  color: rgb(28, 28, 28);
  line-height: 1;
}
.h5line-with-shadows {
  position: absolute;
  width: 85%; /* 或其他固定宽度 */
  height: 50px; /* 根据需要调整，以容纳线条和投影 */
  overflow: hidden; /* 可选，但在这里不是必需的，因为我们控制了子元素的位置 */
  display: flex;
  align-items: center;
  left: 10%;
  top: 47%;
  transform: rotate(-86deg);
  border-radius: 100px;
}
.h5top-shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 11px;
  height: 10px; /* 投影的高度 */
  background: #fbd784;
  opacity: 0.1;
  filter: blur(4px); /* 添加模糊效果 */
  border-radius: 10px;
}
.h5bottom-shadow {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  height: 10px; /* 投影的高度 */
  background: #fbd784;
  opacity: 0.1;
  filter: blur(4px); /* 添加模糊效果 */
  border-radius: 100px;
}
.h5leftCircle {
  height: 15px;
  width: 15px;
  background-color: #fbd784;
  border-radius: 50%;
  margin-right: 2px;
}
.h5rightCircle {
  height: 15px;
  width: 15px;
  background-color: #fbd784;
  border-radius: 50%;
  margin-left: auto;
}

.h5dashed-line {
  position: absolute;
  top: 50%; /* 将线条置于中间，考虑到投影的高度 */
  transform: translateY(-50%); /* 垂直居中线条 */
  left: 0;
  right: 0; /* 或具体宽度，根据需要 */
  height: 2px; /* 线条粗细 */
  background: repeating-linear-gradient(
    to right,
    #fbd784 0,
    #fbd784 4px,
    transparent 1px,
    transparent 17px
  ); /* 创建虚线效果 */
}
.h5page2text {
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.followText {
  margin-right: 18px;
  font-weight: 700;
  font-family: "Inter";
  font-size: 14px;
}
.follow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.followimg {
  height: 18px;
  width: 18px;
  margin-right: 18px;
}
.page1content {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  font-size: 44px;
  font-style: italic;
  line-height: 50px;
  margin-bottom: 40px;
}
.page1title {
  font-family: "Inter";
  font-weight: 800;
  font-size: 14px;
  color: rgba(251, 215, 132, 1);
  letter-spacing: 4.5px;
  margin-bottom: 20px;
  z-index: 5;
}
.page4title {
  font-family: "Inter";
  font-weight: 800;
  font-size: 14px;
  color: rgba(251, 215, 132, 1);
  letter-spacing: 4.5px;
  margin-left: 5px;
  position: absolute;
  left: 4%;
  top: 40%;
}
.h5content {
  color: white;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.hpage1 {
  height: 93vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hpage2 {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hpage4 {
  height: 100vh;
  width: 100%;
  position: relative;
}
.h5leftimg {
  width: 330px;
  height: 169px;
  position: absolute;
  top: 35%;
  left: -40%;
  transform: rotate(10deg);
}
.h5rightimg {
  width: 330px;
  height: 169px;
  position: absolute;
  top: 32%;
  right: -32%;
  z-index: 3;
}
.h5leftimgpage2 {
  width: 320px;
  height: 159px;
  position: absolute;
  top: 70%;
  left: -38%;
  transform: rotate(-10deg);
}
.h5rightimgpage2 {
  width: 330px;
  height: 169px;
  position: absolute;
  top: 10%;
  right: -40%;
  z-index: 3;
}
.card {
  width: 327px;
  height: 371px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #01050780;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  z-index: 2;
  border: 2px solid rgba(251, 215, 132, 0.1);
  margin-top: -20px;
  padding: 15px;
}
.no1Line {
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
  margin-right: 30px;
}
.firstLine {
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: -30%;
  position: absolute;
}
.firstLineText {
  color: #fbd784;
}
.hno1 {
  font-size: 120px;
  line-height: 80px;
  font-weight: 700;
  font-family: "Inter";
  opacity: 0.8;
  color: #ffffff;
}
</style>
