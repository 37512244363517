<template>
  <div class="sliderBox">
    <div class="header" id="slider">
      <div class="btn">
        <div class="title">Follow us</div>
        <img
          @click="gotoTarget('1')"
          class="img"
          src="../assets/twitter.png"
          width="20"
          height="20"
        />
        <img
          @click="gotoTarget('2')"
          class="img"
          src="../assets/M.svg"
          width="20"
          height="20"
        />
        <img
          @click="gotoTarget('3')"
          class="img"
          src="../assets/TG.svg"
          width="20"
          height="20"
        />
        <img
          @click="gotoTarget('4')"
          class="img"
          src="../assets/Discord.svg"
          width="20"
          height="20"
        />
      </div>
      <div class="sliderBarBox">
        <div class="slier">
          <span
            @click="onChange('2')"
            :class="{ active: active === 1 || active === 2 || active === 3 }"
            >Start</span
          >
          <span @click="onChange('4')" :class="{ active: active === 4 }"
            >01</span
          >
          <span @click="onChange('5')" :class="{ active: active === 5 }"
            >02</span
          >
          <span @click="onChange('6')" :class="{ active: active === 6 }"
            >03</span
          >
          <span
            @click="onChange('7')"
            :class="{ active: active === 7 || active === 8 }"
            >04</span
          >
        </div>
        <div class="slidingBar">
          <div class="sliders" id="sliders"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSlider",
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    // 首屏停留1500ms后，开始进行动画
    setTimeout(() => {
      let leftImgElement = document.getElementById("slider");
      leftImgElement.classList.add("sliderAppeaar");
    }, 1500);
  },
  watch: {
    active(newVal, oldVal) {
      if (newVal > oldVal) {
        console.log(newVal, oldVal);
        if (newVal === 4 || newVal === 5 || newVal == 7 || newVal == 6) {
          let rightImgElement = document.getElementById("sliders");
          rightImgElement.className = "";
          rightImgElement.classList.add(`slider${newVal - 2}`);
        }
      } else {
        console.log(newVal, oldVal);
        if (newVal === 4 || newVal === 5  || newVal == 6||newVal === 3 || newVal === 2  ) {
          let rightImgElement = document.getElementById("sliders");
          rightImgElement.className = "";
          rightImgElement.classList.add(`slider${newVal-1}PlayBack`);
        }
      }
    },
  },
  methods: {
    gotoTarget(type) {
      if (type === "1") {
        window.open("https://x.com/ConvergenceFin", "_blank");
      } else if (type === "2") {
        window.open("https://convergencefinance.medium.com/", "_blank");
      } else if (type === "3") {
        window.open("https://t.me/convergencefinanceofficial", "_blank");
      } else if (type === "4") {
        window.open("https://discord.com/invite/G4s5QPEp2y", "_blank");
      }
    },
    onChange(type) {
      this.$emit("onChangePage", type);
    },
  },
};
</script>

<style scoped>
.sliderBox {
  position: absolute;
  width: 100%;
  top: 26%;
  z-index: 4;
}
.sliderAppeaar {
  animation: sliderOpacity 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes sliderOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 4%;
  margin-right: 4%;
  padding-top: 48px;
  opacity: 0;
}
.btn {
  color: white;
  font-weight: bold;
  width: 18px;
}
.slidingBar {
  width: 4px;
  height: 225px;
  background-color: rgb(147, 143, 143);
  border-radius: 5px;
  position: relative;
}
#sliders {
  height: 55px;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
}
.slider2 {
  animation: slider2 1.5s ease-out forwards;
}
.slider2PlayBack {
  animation: slider2PlayBack 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider2PlayBack {
  0% {
    top: 40px;
  }
  100% {
    top: 0px;
  }
}
/* 首屏左图片动画 */
@keyframes slider2 {
  0% {
    top: 0;
  }
  100% {
    top: 40px;
  }
}
.slider3 {
  animation: slider3 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider3 {
  0% {
    top: 40px;
  }
  100% {
    top: 80px;
  }
}
.slider3PlayBack {
  animation: slider3PlayBack 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider3PlayBack {
  0% {
    top: 80px;
  }
  100% {
    top: 40px;
  }
}
.slider4PlayBack {
  animation: slider4PlayBack 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider4PlayBack {
  0% {
    top: 130px;
  }
  100% {
    top: 80px;
  }
}
.slider4 {
  animation: slider4 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider4 {
  0% {
    top: 80px;
  }
  100% {
    top: 130px;
  }
}
.slider5PlayBack {
  animation: slider5PlayBack 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider5PlayBack {
  0% {
    top: 170px;
  }
  100% {
    top: 130px;
  }
}
.slider5 {
  animation: slider5 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes slider5 {
  0% {
    top: 130px;
  }
  100% {
    top: 170px;
  }
}
.title {
  writing-mode: vertical-rl;
  text-align: start;
  cursor: pointer;
}
.sliderBarBox {
  display: flex;
  flex-direction: row;
}
.slier {
  color: rgb(147, 143, 143);
  font-weight: bold;
  width: 80px;
  display: flex;
  flex-direction: column;
  height: 225px;
  justify-content: space-around;
}
.active {
  color: white;
  font-size: 14px;
  text-align: right;
}
span {
  text-align: right;
  margin-right: 25px;
  font-size: 14px;
  cursor: pointer;
}
img {
  margin-top: 15px;
  cursor: pointer;
}
</style>
