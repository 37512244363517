<template>
  <div class="hheaderBox">
    <div class="header" id="header">
      <div class="btn logoBtn">
        <img class="logo" src="../assets//White_400.png" alt="" />
        <span>CONVERGENCE</span>
      </div>
      <div class="rightHeader">
        <div class="btn">LAUNCH APP</div>
        <img src="../assets/arrow1.png" style="margin-left: 10px" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5Header",
  mounted() {
    // 首屏停留1500ms后，开始进行动画
    setTimeout(() => {
      let leftImgElement = document.getElementById("header");
      leftImgElement.classList.add("headerAppeaar");
    }, 1500);
  },
};
</script>

<style scoped>
.hheaderBox {
  position: relative;
}
.rightHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
}
.headerAppeaar {
  animation: headerOpacity 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes headerOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 16px;
  margin-right: 16px;
  opacity: 0;
  font-size: 1;
}
.btn {
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.logo {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.logoBtn {
  display: flex;
  align-items: center;
}
</style>
