var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h5content"},[_c('div',{staticClass:"hpage1"},[_c('img',{staticClass:"h5leftimg",attrs:{"src":require("../assets/left.jpg")}}),_c('img',{staticClass:"h5rightimg",attrs:{"src":require("../assets/right.jpg")}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"page1content"},[_vm._v("AI-Enabled Trading Algorithm Platform")]),_c('div',{staticClass:"follow"},[_c('div',{staticClass:"followText"},[_vm._v("Follow us")]),_c('img',{staticClass:"followimg",attrs:{"src":require("../assets/twitter.png"),"width":"20","height":"20"},on:{"click":function($event){return _vm.gotoTarget('1')}}}),_c('img',{staticClass:"followimg",attrs:{"src":require("../assets/M.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.gotoTarget('2')}}}),_c('img',{staticClass:"followimg",attrs:{"src":require("../assets/TG.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.gotoTarget('3')}}}),_c('img',{staticClass:"followimg",attrs:{"src":require("../assets/Discord.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.gotoTarget('4')}}})])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"hpage7"},[_c('div',{staticClass:"hpage7Text"},[_vm._v(" Convergence transforms the trading landscape by combining cutting-edge AI with robust security, delivering unparalleled trading efficiency and profitability. ")]),_c('div'),_c('div',{staticClass:"copy"},[_c('img',{staticStyle:{"margin-bottom":"5px"},attrs:{"src":require("../assets/copy.png"),"alt":""}}),_c('div',{staticClass:"copytext"},[_vm._v("AI-Enabled Trading Algorithm Platform")]),_c('div',{staticClass:"copytime"},[_vm._v(" "+_vm._s(`Copyright ${new Date().getFullYear()} Convergence, Inc. Terms & Privacy`)+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page1title"},[_c('div',[_vm._v("CONVERGENCE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hpage2"},[_c('img',{staticClass:"h5leftimgpage2",attrs:{"src":require("../assets/left.jpg")}}),_c('img',{staticClass:"h5rightimgpage2",attrs:{"src":require("../assets/right.jpg")}}),_c('div',{staticClass:"h5page2text"},[_c('span',[_vm._v("Convergence leverages")]),_c('span',{staticStyle:{"background-color":"#fbd784","color":"black"}},[_vm._v("advanced AI")]),_c('span',[_vm._v("to")]),_c('span',{staticStyle:{"background-color":"#fbd784","color":"black"}},[_vm._v("revolutionize trading algorithms,")]),_c('span',[_vm._v("offering a seamless and secure solution for traders seeking to maximize profitability while protecting proprietary strategies.")])]),_c('div',{staticClass:"h5line-with-shadows"},[_c('div',{staticClass:"h5leftCircle"}),_c('div',{staticClass:"h5top-shadow"}),_c('div',{staticClass:"h5dashed-line"}),_c('div',{staticClass:"h5bottom-shadow"}),_c('div',{staticClass:"h5rightCircle"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hpage4"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"h5Noleft"},[_vm._v("01")]),_c('div',{staticClass:"page4title"},[_vm._v("PROBLEM STATEMENT")])]),_c('div',{staticClass:"h5page4content"},[_c('div',[_c('div',{staticClass:"itemContent",staticStyle:{"margin-top":"16px"}},[_vm._v(" Trading, fundamentally a matter of buying low and selling high, poses intricate challenges: ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Entry and Exit Points")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Determining optimal buy and sell points. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Volume Decisions")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Deciding how much to trade at each point. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("AI Integration")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Incorporating AI into trading environments without compromising security or efficiency. ")])]),_c('div',{staticClass:"h5page5imgBox"},[_c('img',{staticClass:"hpage3Img1",attrs:{"src":require("../assets/01top.png"),"alt":""}}),_c('img',{staticClass:"hpage3Img2",attrs:{"src":require("../assets/01bottom.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hpage4"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"h5Noleft"},[_vm._v("02")]),_c('div',{staticClass:"page4title"},[_vm._v("CAPABILITIES")])]),_c('div',{staticClass:"h5page4content"},[_c('div',[_c('div',{staticClass:"itemTitle"},[_vm._v("Algorithm Security")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Proprietary algorithms operate within a black-box environment, safeguarding the author’s strategies. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Dynamic Strategy Updates")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Continuous adaptation to the latest market conditions, ensuring strategies remain effective. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Real-Time Feedback")]),_c('div',{staticClass:"itemContent"},[_vm._v(" AI delivers rapid responses to market fluctuations, enhancing decision-making. ")])]),_c('div',{staticClass:"h5page5imgBox"},[_c('img',{staticClass:"hpage4Img1",attrs:{"src":require("../assets/02top.png"),"alt":""}}),_c('img',{staticClass:"hpage4Img2",attrs:{"src":require("../assets/02bottom.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hpage4"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"h5Noleft"},[_vm._v("03")]),_c('div',{staticClass:"page4title"},[_vm._v("CHALLENGES")])]),_c('div',{staticClass:"h5page4content"},[_c('div',[_c('div',{staticClass:"itemTitle"},[_vm._v("Tool Limitations")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Current market tools often simplify the programming process but lack sophistication in guiding trading decisions. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Strategy Confidentiality")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Expert traders are reluctant to share their high-performance algorithms to maintain competitive advantage. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Retail Demand")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Retail investors seek low-risk, high-return products but face limited options. ")])]),_c('div',{staticClass:"h5page5imgBox"},[_c('img',{staticClass:"hpage6Img1",attrs:{"src":require("../assets/03top.png"),"alt":""}}),_c('img',{staticClass:"hpage6Img2",attrs:{"src":require("../assets/03bottom.png"),"alt":""}}),_c('img',{staticClass:"hpage6Img3",attrs:{"src":require("../assets/03middle.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hpage4"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"h5Noleft"},[_vm._v("04")]),_c('div',{staticClass:"page4title"},[_vm._v("SOLUTIONS")])]),_c('div',{staticClass:"h5page4content"},[_c('div',[_c('div',{staticClass:"itemTitle"},[_vm._v("Advanced Algorithms")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Built with world-class trading algorithms, Convergence ensures superior performance. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("AI-Driven Optimization")]),_c('div',{staticClass:"itemContent"},[_vm._v(" AI fine-tunes trading algorithms for maximum profit. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("Secure Execution")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Fully Homomorphic Encryption (FHE) technology guarantees the confidentiality of trading bot code, preventing leaks. ")]),_c('div',{staticClass:"itemTitle"},[_vm._v("User-Friendly Returns")]),_c('div',{staticClass:"itemContent"},[_vm._v(" Provides users with one-click access to optimized trading returns. ")])]),_c('div',{staticClass:"h5page5imgBox",staticStyle:{"padding-top":"20px"}},[_c('img',{staticClass:"h5page7Img1",attrs:{"src":require("../assets/04top1.png"),"alt":""}}),_c('img',{staticClass:"h5page7Img2",attrs:{"src":require("../assets/04top2.png"),"alt":""}}),_c('img',{staticClass:"h5page7Img3",attrs:{"src":require("../assets/04top3.png"),"alt":""}}),_c('img',{staticClass:"h5page7Img4",attrs:{"src":require("../assets/04top4.png"),"alt":""}}),_c('img',{staticClass:"h5page7Img5",attrs:{"src":require("../assets/04bottom.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }