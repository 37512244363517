<template>
  <div class="contain" v-if="platfrom === 'PC'">
    <app-header />
    <slider
      :active="pageName"
      :scrollDirection="scrollDirection"
      @onChangePage="onChangePage"
    ></slider>
    <div class="content">
      <img
        class="initLeftImg"
        id="leftImg"
        src="./assets/left.jpg"
        v-show="leftImg"
      />
      <img
        class="initRightImg"
        id="rightImg"
        src="./assets/right.jpg"
        v-show="rightImg"
      />
      <div class="page2" id="page2" v-show="page2Show">
        <div class="title">
          <div class="line"></div>
          <div style="letter-spacing: 5px">CONVERGENCE</div>
        </div>
        <div class="text">AI-Enabled Trading Algorithm Platform</div>
        <div class="next">
          <div>scroll down</div>
          <img
            src="./assets/arrow.png"
            style="height: 20px; width: 20px; margin-left: 10px"
            alt=""
          />
        </div>
      </div>
      <div class="page3" id="page3" v-show="page3Show">
        <span>Convergence leverages</span>
        <span style="background-color: #fbd784; color: black">advanced AI</span>
        <span>to</span>
        <span style="background-color: #fbd784; color: black"
          >revolutionize trading algorithms,</span
        >
        <span
          >offering a seamless and secure solution for traders seeking to
          maximize profitability while protecting proprietary strategies.</span
        >
      </div>
      <div class="line-with-shadows" id="lines" v-show="page3LineShow">
        <div class="leftCircle"></div>
        <div class="top-shadow"></div>
        <div class="dashed-line"></div>
        <div class="bottom-shadow"></div>
        <div class="rightCircle"></div>
      </div>

      <div class="page4" id="page4" v-show="page4Show">
        <div class="page4Text">
          <div class="no1" id="no1">01</div>
          <div class="firstLine" id="firstLine">
            <div class="no1Line"></div>
            <div class="firstLineText">PROBLEM STATEMENT</div>
          </div>
          <div class="secLine" id="secLine">
            Trading, fundamentally a matter of buying low and selling high,
            poses intricate challenges:
          </div>
          <div class="thirdLine" id="thirdLine">
            <div class="third1">Entry and Exit Points</div>
            <div style="margin-top: 5px">
              Determining optimal buy and sell points.
            </div>
          </div>
          <div class="fourthLine" id="fourthLine">
            <div class="third1">Volume Decisions</div>
            <div style="margin-top: 5px">
              Deciding how much to trade at each point.
            </div>
          </div>
          <div class="fifthLine" id="fifthLine">
            <div class="third1">AI Integration</div>
            <div style="margin-top: 5px">
              Incorporating AI into trading environments without compromising
              security or efficiency.
            </div>
          </div>
        </div>
        <div class="page4Imgs">
          <img
            class="page4Img1"
            id="page4Img1"
            src="./assets/01top.png"
            alt=""
          />
          <img
            class="page4Img2"
            id="page4Img2"
            style="margin-top: 20px"
            src="./assets/01bottom.png"
            alt=""
          />
        </div>
      </div>
      <div class="page5" id="page5">
        <div class="page5Imgs">
          <img
            class="page5Img1"
            id="page5Img1"
            src="./assets/02top.png"
            alt=""
          />
          <img
            class="page5Img2"
            id="page5Img2"
            style="margin-top: 20px"
            src="./assets/02bottom.png"
            alt=""
          />
        </div>
        <div class="page5Text">
          <div class="no2" id="no2">02</div>
          <div class="page5firstLine" id="page5firstLine">
            <div class="page5no1Line"></div>
            <div class="page5firstLineText">CAPABILITIES</div>
          </div>
          <div class="page5thirdLine" id="page5thirdLine">
            <div class="page5third1">Algorithm Security</div>
            <div style="margin-top: 5px">
              Proprietary algorithms operate within a black-box environment,
              safeguarding the author’s strategies.
            </div>
          </div>
          <div class="page5fourthLine" id="page5fourthLine">
            <div class="page5third1">Dynamic Strategy Updates</div>
            <div style="margin-top: 5px">
              Continuous adaptation to the latest market conditions, ensuring
              strategies remain effective.
            </div>
          </div>
          <div class="page5fifthLine" id="page5fifthLine">
            <div class="page5third1">Real-Time Feedback</div>
            <div style="margin-top: 5px">
              AI delivers rapid responses to market fluctuations, enhancing
              decision-making.
            </div>
          </div>
        </div>
      </div>
      <div class="page6" id="page6">
        <div class="page6Text">
          <div class="no3" id="no3">03</div>
          <div class="page6firstLine" id="page6firstLine">
            <div class="page6no1Line"></div>
            <div class="page6firstLineText">CHALLENGES</div>
          </div>
          <div class="page6thirdLine" id="page6thirdLine">
            <div class="page6third1">Tool Limitations</div>
            <div style="margin-top: 5px">
              Current market tools often simplify the programming process but
              lack sophistication in guiding trading decisions.
            </div>
          </div>
          <div class="page6fourthLine" id="page6fourthLine">
            <div class="page6third1">Strategy Confidentiality</div>
            <div style="margin-top: 5px">
              Expert traders are reluctant to share their high-performance
              algorithms to maintain competitive advantage.
            </div>
          </div>
          <div class="page6fifthLine" id="page6fifthLine">
            <div class="page6third1">Retail Demand</div>
            <div style="margin-top: 5px">
              Retail investors seek low-risk, high-return products but face
              limited options.
            </div>
          </div>
        </div>
        <div class="page6Imgs">
          <img
            class="page6Img1"
            id="page6Img1"
            src="./assets/03top.png"
            alt=""
          />
          <img
            class="page6Img2"
            id="page6Img2"
            style="margin-top: 20px"
            src="./assets/03bottom.png"
            alt=""
          />
          <img
            class="page6Img3"
            id="page6Img3"
            style="margin-top: 20px"
            src="./assets/03middle.png"
            alt=""
          />
        </div>
      </div>
      <div class="page7" id="page7">
        <div class="page7Imgs">
          <img
            class="page7Img1"
            id="page7Img1"
            src="./assets/04top1.png"
            alt=""
          />
          <img
            class="page7Img2"
            id="page7Img2"
            style="margin-top: 20px"
            src="./assets/04top2.png"
            alt=""
          />
          <img
            class="page7Img3"
            id="page7Img3"
            style="margin-top: 20px"
            src="./assets/04top3.png"
            alt=""
          />
          <img
            class="page7Img4"
            id="page7Img4"
            style="margin-top: 20px"
            src="./assets/04top4.png"
            alt=""
          />
          <img
            class="page7Img5"
            id="page7Img5"
            style="margin-top: 20px"
            src="./assets/04bottom.png"
            alt=""
          />
        </div>
        <div class="page7Text">
          <div class="no4" id="no4">04</div>
          <div class="page7firstLine" id="page7firstLine">
            <div class="page7no1Line"></div>
            <div class="page7firstLineText">SOLUTIONS</div>
          </div>
          <div class="page7secondLine" id="page7secondLine">
            <div class="page7third1">Advanced Algorithms</div>
            <div style="position: absolute; top: 40%; left: 0">
              Built with world-class trading algorithms, Convergence ensures
              superior performance.
            </div>
          </div>
          <div class="page7thirdLine" id="page7thirdLine">
            <div class="page7third1">AI-Driven Optimization</div>
            <div style="position: absolute; top: 40%; left: 0">
              AI fine-tunes trading algorithms for maximum profit.
            </div>
          </div>
          <div class="page7fouthLine" id="page7fouthLine">
            <div class="page7third1">Secure Execution</div>
            <div style="position: absolute; top: 40%; left: 0">
              Fully Homomorphic Encryption (FHE) technology guarantees the
              confidentiality of trading bot code, preventing leaks.
            </div>
          </div>
          <div class="page7fifthLine" id="page7fifthLine">
            <div class="page7third1">User-Friendly Returns</div>
            <div style="position: absolute; top: 40%; left: 0">
              Provides users with one-click access to optimized trading returns.
            </div>
          </div>
        </div>
      </div>
      <div class="page8" id="page8">
        <div class="page8text" id="page8text">
          Convergence transforms the trading landscape by combining cutting-edge
          AI with robust security, delivering unparalleled trading efficiency
          and profitability.
        </div>
        <div class="page8line" id="page8line"></div>
      </div>
    </div>
    <div class="footer" id="footer">
      <div class="footerLeft">
        <img class="footerImg" src="./assets/copy.png" alt="" />
        <div class="footerText">AI-Enabled Trading Algorithm Platform</div>
      </div>
      <div class="footerText" style="margin-right: 4%">
        {{
          `Copyright ${new Date().getFullYear()} Convergence, Inc. Terms & Privacy`
        }}
      </div>
    </div>
  </div>
  <div v-else class="contain">
    <h5-header />
    <h5-content />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import Slider from "./components/Slider.vue";
import H5Header from "./components/H5Header.vue";
import H5Content from "./components/H5Content.vue";
export default {
  name: "AppHome",
  components: {
    AppHeader,
    Slider,
    H5Header,
    H5Content,
  },
  data() {
    return {
      isFirstLoading: true,
      throttledHandleWheel: null,
      currentPage: 1,
      platfrom: "PC",
      scrollDirection: 0,
      pageName: 1,
      target: 2,

      page3Show: true,
      rightImg: true,
      leftImg: true,
      page2Show: true,
      page3LineShow: true,
      page4Show: true,
    };
  },
  mounted() {
    window.addEventListener("resize", this.detectDeviceType);
    if (this.platfrom === "PC") {
      // 首屏停留1500ms后，开始进行动画
      setTimeout(() => {
        let leftImgElement = document.getElementById("leftImg");
        let rightImgElement = document.getElementById("rightImg");
        leftImgElement.classList.add("move-leftImg");
        rightImgElement.classList.add("move-rightImg");
        let page2Element = document.getElementById("page2");
        page2Element.classList.add("page2Appear");
        this.isFirstLoading = false;
        this.pageName = 2;
        this.pageSwitch();
      }, 1500);
    }
  },

  // 创建并保存节流后的 handleWheel 函数
  created() {
    this.detectDeviceType();
    if (this.platfrom === "PC") {
      this.throttledHandleWheel = this.throttle(this.handleWheel, 200);
    }
  },
  beforeDestroy() {
    if (this.platfrom === "PC") {
      // 组件销毁前移除事件监听
      window.removeEventListener("wheel", this.throttledHandleWheel);
      window.removeEventListener("resize", this.detectDeviceType);
    }
  },
  watch: {
    platfrom(newVal) {
      if (newVal === "PC") {
        location.reload();
      }
    },
  },

  methods: {
    detectDeviceType() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let platform = "PC";
      // 判断是否是移动设备
      if (/windows phone/i.test(userAgent)) {
        platform = "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        platform = "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        platform = "iOS";
      }
      this.platfrom = platform;
    },
    // 在组件中使用节流函数
    pageSwitch() {
      window.addEventListener("wheel", (event) => {
        this.scrollDirection = Math.sign(event.deltaY);
        this.throttledHandleWheel(event);
      });
    },
    throttle(func, wait) {
      // 维护一个定时器
      let timeout;
      // 返回一个函数，这个函数会在一个时间间隔后执行
      return function () {
        // 上下文和参数
        const context = this;
        const args = arguments;
        // 如果定时器存在，则清除它
        if (timeout !== undefined) {
          clearTimeout(timeout);
        }
        // 设置一个新的定时器，该定时器到期后执行真正的函数
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    // 清空后，重置类型
    resetClassName(id, initClassNmme, currentClassName) {
      let element = document.getElementById(id);
      if (element && element.classList) {
        while (element.classList.length > 0) {
          element.classList.remove(element.classList.item(0));
        }
        if (initClassNmme) {
          element.classList.add(initClassNmme);
        }
        if (currentClassName) {
          element.classList.add(currentClassName);
        }
      }
    },
    // 清空当前样式，初始化目标页样式
    onChangePage(type) {
      console.log(type, this.pageName);
      if (
        (type === "2" && (this.pageName === 2 || this.pageName === 3)) ||
        type == this.pageName
      ) {
        return;
      } else {
        if (this.pageName === 4) {
          //当前在No1页
          if (type === "2") {
            // 跳转到第二页
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 1500);
          } else if (type === "5") {
            //在No2页 当前页的下一页直接调用方法
            this.scrollDirection = 1;
            this.pageName = 4;
            this.handleWheel();
          } else if (type === "6") {
            this.scrollDirection = 1;
            this.pageName = 4;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 1500);
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.pageName = 4;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 3000);
          }
        } else if (this.pageName === 2) {
          // 当前在第二页
          if (type === "4") {
            // 前往No1页
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel(true);
            }, 1500);
          } else if (type === "5") {
            this.target = 5;
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 3000);
          } else if (type === "6") {
            this.scrollDirection = 1;
            this.handleWheel(true);
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 6000);
          }
        } else if (this.pageName === 3) {
          // 当前在第三页
          if (type === "4") {
            this.scrollDirection = 1;
            this.handleWheel();
          } else if (type === "5") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel();
            }, 1500);
          } else if (type === "6") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 3000);
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 4500);
          }
        } else if (this.pageName === 5) {
          // No2页
          if (type === "6") {
            this.scrollDirection = 1;
            this.handleWheel();
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.pageName = 5;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 1500);
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel();
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 3000);
          }
        } else if (this.pageName === 6) {
          if (type === "7") {
            this.scrollDirection = 1;
            this.handleWheel();
          } else if (type === "5") {
            this.scrollDirection = -1;
            this.handleWheel();
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 1500);
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 4500);
          }
        } else if (this.pageName === 7) {
          if (type === "5") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 1500);
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 3000);
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 4500);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 6000);
          } else if (type === "6") {
            this.scrollDirection = -1;
            this.handleWheel();
          }
        } else if (this.pageName == 8) {
          if (type === "7") {
            this.scrollDirection = -1;
            this.handleWheel();
          } else if (type === "6") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel();
            }, 1500);
          } else if (type === "5") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 3000);
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
          } else if (type === "3") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel();
            }, 6000);
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel();
            }, 6000);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 7500);
          }
        }
      }
    },
    handleWheel(opacity) {
      if (typeof opacity != "boolean") opacity = false;
      if (this.isFirstLoading) return;
      if (this.scrollDirection > 0) {
        let leftImgElement = document.getElementById("leftImg");
        let rightImgElement = document.getElementById("rightImg");
        let page3Element = document.getElementById("page3");
        let page3linesElement = document.getElementById("lines");
        let cardElement = document.getElementById("page2");
        // 三屏动画
        if (this.pageName === 2) {
          this.resetClassName("rightImg", "initRightImg");
          this.resetClassName("leftImg", "initLeftImg");
          this.resetClassName("page3", "page3");
          this.resetClassName("lines", "line-with-shadows");
          this.resetClassName("page2", "page2");

          cardElement.classList.add("fade-in");
          leftImgElement.classList.add("sacle-leftImg");
          rightImgElement.classList.add("sacle-rightImg");
          page3Element.classList.add("page3Appear");
          page3linesElement.classList.add("page2Appearline");
          this.pageName = 3;
          return;
        }

        let page4ImgElement1 = document.getElementById("page4Img1");
        let page4ImgElement2 = document.getElementById("page4Img2");
        let page4no1 = document.getElementById("no1");
        let page4firstLine = document.getElementById("firstLine");
        let page4secLine = document.getElementById("secLine");
        let page4thirdLine = document.getElementById("thirdLine");
        let page4fourthLine = document.getElementById("fourthLine");
        let page4fifthLine = document.getElementById("fifthLine");
        // 四屏动画
        if (this.pageName === 3) {
          this.resetClassName("page4", "page4");
          this.resetClassName("leftImg", "initLeftImg");
          this.resetClassName("rightImg", "initRightImg");
          this.resetClassName("page3", "page3");
          this.resetClassName("page4Img1", "page4Img1");
          this.resetClassName("page4Img2", "page4Img2");
          this.resetClassName("no1", "no1");
          this.resetClassName("firstLine", "firstLine");
          this.resetClassName("secLine", "secLine");
          this.resetClassName("thirdLine", "thirdLine");
          this.resetClassName("fourthLine", "fourthLine");
          this.resetClassName("fifthLine", "fifthLine");

          leftImgElement.classList.add("hide-leftImg");
          rightImgElement.classList.add("hide-rightImg");
          page3Element.classList.add("page3Hide");
          page3linesElement.classList.add("page3linesHide");
          page4ImgElement1.classList.add("appear-page4img1");
          page4ImgElement2.classList.add("appear-page4img2");
          page4no1.classList.add("appear-page4no1");
          page4firstLine.classList.add("appear-page4firstLine");
          page4secLine.classList.add("appear-page4secLine");
          page4thirdLine.classList.add("appear-page4thirdLine");
          page4fourthLine.classList.add("appear-page4fourthLine");
          page4fifthLine.classList.add("appear-page4fifthLine");
          this.pageName = 4;
          return;
        }
        // 五屏动画
        let page4Element = document.getElementById("page4");
        let page5ImgElement1 = document.getElementById("page5Img1");
        let page5ImgElement2 = document.getElementById("page5Img2");
        let page5no1 = document.getElementById("no2");
        let page5firstLine = document.getElementById("page5firstLine");
        let page5thirdLine = document.getElementById("page5thirdLine");
        let page5fourthLine = document.getElementById("page5fourthLine");
        let page5fifthLine = document.getElementById("page5fifthLine");
        if (this.pageName === 4) {
          this.resetClassName("page4", "page4");
          this.resetClassName("page5Img1", "page5Img1");
          this.resetClassName("page5Img2", "page5Img2");
          this.resetClassName("no2", "no2");
          this.resetClassName("page5firstLine", "page5firstLine");
          this.resetClassName("page5thirdLine", "page5thirdLine");
          this.resetClassName("page5fourthLine", "page5fourthLine");
          this.resetClassName("page5fifthLine", "page5fifthLine");

          page4Element.classList.add("page4-hide");
          page5ImgElement1.classList.add("appear-page5img1");
          page5ImgElement2.classList.add("appear-page5img2");
          page5no1.classList.add("appear-page5no1");
          page5firstLine.classList.add("appear-page5firstLine");
          page5thirdLine.classList.add("appear-page5thirdLine");
          page5fourthLine.classList.add("appear-page5fourthLine");
          page5fifthLine.classList.add("appear-page5fifthLine");
          this.pageName = 5;
          return;
        }
        // 六屏动画
        let page5Element = document.getElementById("page5");
        let page6ImgElement1 = document.getElementById("page6Img1");
        let page6ImgElement2 = document.getElementById("page6Img2");
        let page6ImgElement3 = document.getElementById("page6Img3");
        let page6no1 = document.getElementById("no3");
        let page6firstLine = document.getElementById("page6firstLine");
        let page6thirdLine = document.getElementById("page6thirdLine");
        let page6fourthLine = document.getElementById("page6fourthLine");
        let page6fifthLine = document.getElementById("page6fifthLine");
        if (this.pageName === 5) {
          this.resetClassName("page5", "page5");
          this.resetClassName("page6Img1", "page6Img1");
          this.resetClassName("page6Img2", "page6Img2");
          this.resetClassName("page6Img3", "page6Img3");
          this.resetClassName("no3", "no3");
          this.resetClassName("page6firstLine", "page6firstLine");
          this.resetClassName("page6thirdLine", "page6thirdLine");
          this.resetClassName("page6fourthLine", "page6fourthLine");
          this.resetClassName("page6fifthLine", "page6fifthLine");

          page5Element.classList.add("page5-hide");
          page6ImgElement1.classList.add("appear-page6img1");
          page6ImgElement2.classList.add("appear-page6img2");
          page6ImgElement3.classList.add("appear-page6img3");
          page6no1.classList.add("appear-page6no1");
          page6firstLine.classList.add("appear-page6firstLine");
          page6thirdLine.classList.add("appear-page6thirdLine");
          page6fourthLine.classList.add("appear-page6fourthLine");
          page6fifthLine.classList.add("appear-page6fifthLine");
          this.pageName = 6;
          this.resetClassName("footer", "footer");
          return;
        }
        // 七屏动画
        let page6Element = document.getElementById("page6");
        let page7ImgElement1 = document.getElementById("page7Img1");
        let page7ImgElement2 = document.getElementById("page7Img2");
        let page7ImgElement3 = document.getElementById("page7Img3");
        let page7ImgElement4 = document.getElementById("page7Img4");
        let page7ImgElement5 = document.getElementById("page7Img5");
        let page7no1 = document.getElementById("no4");
        let page7firstLine = document.getElementById("page7firstLine");
        let page7secondLine = document.getElementById("page7secondLine");
        let page7thirdLine = document.getElementById("page7thirdLine");
        let page7fourthLine = document.getElementById("page7fouthLine");
        let page7fifthLine = document.getElementById("page7fifthLine");
        let page8footer = document.getElementById("footer");
        if (this.pageName === 6) {
          this.resetClassName("page6", "page6");
          this.resetClassName("page7Img1", "page7Img1");
          this.resetClassName("page7Img2", "page7Img2");
          this.resetClassName("page7Img3", "page7Img3");
          this.resetClassName("page7Img4", "page7Img4");
          this.resetClassName("page7Img5", "page7Img5");
          this.resetClassName("no4", "no4");
          this.resetClassName("page7firstLine", "page7firstLine");
          this.resetClassName("page7secondLine", "page7secondLine");
          this.resetClassName("page7thirdLine", "page7thirdLine");
          this.resetClassName("page7fouthLine", "page7fouthLine");
          this.resetClassName("page7fifthLine", "page7fifthLine");

          page6Element.classList.add("page6-hide");
          page7ImgElement1.classList.add("appear-page7img1");
          page7ImgElement2.classList.add("appear-page7img2");
          page7ImgElement3.classList.add("appear-page7img3");
          page7ImgElement4.classList.add("appear-page7img4");
          page7ImgElement5.classList.add("appear-page7img5");
          page7no1.classList.add("appear-page7no1");
          page7firstLine.classList.add("appear-page7firstLine");
          page7secondLine.classList.add("appear-page7secondLine");
          page7thirdLine.classList.add("appear-page7thirdLine");
          page7fourthLine.classList.add("appear-page7fouthLine");
          page7fifthLine.classList.add("appear-page7fifthLine");
          this.resetClassName("footer", "footer");
          this.pageName = 7;
          return;
        }

        let page7Element = document.getElementById("page7");
        let page8text = document.getElementById("page8text");
        let page8Line = document.getElementById("page8line");
        if (this.pageName === 7) {
          this.resetClassName("page7", "page7");
          this.resetClassName("page8text", "page8text");
          this.resetClassName("page8line", "page8line");
          this.resetClassName("footer", "footer");

          page7Element.classList.add("page7-hide");
          page8text.classList.add("page8-text");
          page8Line.classList.add("page8-line");
          page8footer.classList.add("footershow");
          this.pageName = 8;
        }
      } else {
        console.log("滚动上", this.pageName);
        if (this.pageName === 3) {
          // 当前在第二页进行回滚到上一页
          let leftImgElement = document.getElementById("leftImg");
          let rightImgElement = document.getElementById("rightImg");
          let page3Element = document.getElementById("page3");
          let page3linesElement = document.getElementById("lines");
          let cardElement = document.getElementById("page2");

          this.resetClassName("rightImg", "initRightImg");
          this.resetClassName("leftImg", "initLeftImg");
          this.resetClassName("page3", "page3");
          this.resetClassName("lines", "line-with-shadows", "page2Appearline");

          cardElement.classList.add("cardPlayBack");
          rightImgElement.classList.add("sacleRightImgPlayBack");
          leftImgElement.classList.add("sacleLeftImgPlayBack");
          page3Element.classList.add("page3PlayBack");
          page3linesElement.classList.add("page3linePlayBack");
          this.pageName = 2;
          return;
        } else if (this.pageName === 4) {
          // 当前在第三页进行回滚到上一页
          this.resetClassName("lines", "line-with-shadows", "page2Appearline");
          let page4ImgElement1 = document.getElementById("page4Img1");
          let page4ImgElement2 = document.getElementById("page4Img2");
          let page4no1 = document.getElementById("no1");
          let page4firstLine = document.getElementById("firstLine");
          let page4secLine = document.getElementById("secLine");
          let page4thirdLine = document.getElementById("thirdLine");
          let page4fourthLine = document.getElementById("fourthLine");
          let page4fifthLine = document.getElementById("fifthLine");
          let leftImgElement = document.getElementById("leftImg");
          let rightImgElement = document.getElementById("rightImg");
          let page3Element = document.getElementById("page3");

          this.resetClassName("page4", "page4");

          leftImgElement.classList.add("hideLeftImgPlayBack");
          rightImgElement.classList.add("hideRightImgPlayBack");
          page3Element.classList.add("page3HidePlayBack");
          page4ImgElement1.classList.add("appearPage4img1PlayBack");
          page4ImgElement2.classList.add("appearPage4img2PlayBack");
          page4no1.classList.add("appearPage4no1PlayBack");
          page4firstLine.classList.add("appearPage4firstLinePlayBack");
          page4secLine.classList.add("appearPage4secLinePlayBack");
          page4thirdLine.classList.add("appearPage4thirdLinepalyBack");
          page4fourthLine.classList.add("appearPage4fourthLinePalyBack");
          page4fifthLine.classList.add("appearPage4fifthLinePalyBack");
          this.pageName = 3;
        } else if (this.pageName === 5) {
          // 当前在第四页进行回滚到上一页
          let page4Element = document.getElementById("page4");
          let page5ImgElement1 = document.getElementById("page5Img1");
          let page5ImgElement2 = document.getElementById("page5Img2");
          let page5no1 = document.getElementById("no2");
          let page5firstLine = document.getElementById("page5firstLine");
          let page5thirdLine = document.getElementById("page5thirdLine");
          let page5fourthLine = document.getElementById("page5fourthLine");
          let page5fifthLine = document.getElementById("page5fifthLine");

          this.resetClassName("page4", "page4");

          page4Element.classList.add("page4HidePlayBack");
          page5ImgElement1.classList.add("appearPage5img1PlayBack");
          page5ImgElement2.classList.add("appearPage5img2PlayBack");
          page5no1.classList.add("appearPage5no1PlayBack");
          page5firstLine.classList.add("appearPage5firstLinePlayBack");
          page5thirdLine.classList.add("appearPage5thirdLinePlayBack");
          page5fourthLine.classList.add("appearPage5fourthLinePlayBack");
          page5fifthLine.classList.add("appearPage5fifthLinePlayBack");
          this.pageName = 4;
        } else if (this.pageName === 6) {
          // 当前在第五页进行回滚到上一页
          let page5Element = document.getElementById("page5");
          let page6ImgElement1 = document.getElementById("page6Img1");
          let page6ImgElement2 = document.getElementById("page6Img2");
          let page6ImgElement3 = document.getElementById("page6Img3");
          let page6no1 = document.getElementById("no3");
          let page6firstLine = document.getElementById("page6firstLine");
          let page6thirdLine = document.getElementById("page6thirdLine");
          let page6fourthLine = document.getElementById("page6fourthLine");
          let page6fifthLine = document.getElementById("page6fifthLine");

          this.resetClassName("page5", "page5");
          this.resetClassName("page6Img1", "page6Img1");
          this.resetClassName("page6Img2", "page6Img2");
          this.resetClassName("page6Img3", "page6Img3");
          this.resetClassName("no3", "no3");
          this.resetClassName("page6firstLine", "page6firstLine");
          this.resetClassName("page6thirdLine", "page6thirdLine");
          this.resetClassName("page6fourthLine", "page6fourthLine");
          this.resetClassName("page6fifthLine", "page6fifthLine");

          page5Element.classList.add("page5HidePlayBack");
          page6ImgElement1.classList.add("appearPage6img1PlayBack");
          page6ImgElement2.classList.add("appearPage6img2PlayBack");
          page6ImgElement3.classList.add("appearPage6img3PlayBak");
          page6no1.classList.add("appearPage6no1PlayBack");
          page6firstLine.classList.add("appearPage6firstLinePlayBack");
          page6thirdLine.classList.add("appearPage6thirdLinePlayBack");
          page6fourthLine.classList.add("appearPage6fourthLinePlayBack");
          page6fifthLine.classList.add("appearPage6fifthLinePlayBack");
          this.pageName = 5;
          return;
        } else if (this.pageName === 7) {
          let page6Element = document.getElementById("page6");
          let page7ImgElement1 = document.getElementById("page7Img1");
          let page7ImgElement2 = document.getElementById("page7Img2");
          let page7ImgElement3 = document.getElementById("page7Img3");
          let page7ImgElement4 = document.getElementById("page7Img4");
          let page7ImgElement5 = document.getElementById("page7Img5");
          let page7no1 = document.getElementById("no4");
          let page7firstLine = document.getElementById("page7firstLine");
          let page7secondLine = document.getElementById("page7secondLine");
          let page7thirdLine = document.getElementById("page7thirdLine");
          let page7fourthLine = document.getElementById("page7fouthLine");
          let page7fifthLine = document.getElementById("page7fifthLine");

          this.resetClassName("page6", "page6");
          this.resetClassName("page7Img1", "page7Img1");
          this.resetClassName("page7Img2", "page7Img2");
          this.resetClassName("page7Img3", "page7Img3");
          this.resetClassName("page7Img4", "page7Img4");
          this.resetClassName("page7Img5", "page7Img5");
          this.resetClassName("no4", "no4");
          this.resetClassName("page7firstLine", "page7firstLine");
          this.resetClassName("page7secondLine", "page7secondLine");
          this.resetClassName("page7thirdLine", "page7thirdLine");
          this.resetClassName("page7fouthLine", "page7fouthLine");
          this.resetClassName("page7fifthLine", "page7fifthLine");

          page6Element.classList.add("page6HidePlayBack");
          page7ImgElement1.classList.add("appearPage7img1PlayBack");
          page7ImgElement2.classList.add("appearPage7img2PlayBack");
          page7ImgElement3.classList.add("appearPage7img3PlayBack");
          page7ImgElement4.classList.add("appearPage7img4PlayBack");
          page7ImgElement5.classList.add("appearPage7img5PlayBack");
          page7no1.classList.add("appearPage7no1PlayBack");
          page7firstLine.classList.add("appearPage7firstLinePlayBack");
          page7secondLine.classList.add("appearPage7secondLinePlayBack");
          page7thirdLine.classList.add("appearPage7thirdLinePlayBack");
          page7fourthLine.classList.add("appearPage7fouthLinePlayBack");
          page7fifthLine.classList.add("appearPage7fifthLinePlayBack");
          this.resetClassName("footer", "footer");
          this.pageName = 6;
          return;
        } else if (this.pageName === 8) {
          let page7Element = document.getElementById("page7");
          let page8text = document.getElementById("page8text");
          let page8Line = document.getElementById("page8line");
          let page8footer = document.getElementById("footer");
          this.resetClassName("footer", "footer");
          this.resetClassName("page7", "page7");
          this.resetClassName("page8text", "page8text");
          this.resetClassName("page8line", "page8line");

          page7Element.classList.add("page7HidePlayBack");
          page8text.classList.add("page8TextPlayBack");
          page8Line.classList.add("page8LinePlayBack");
          page8footer.classList.add("footerhide");
          this.pageName = 7;
        }
      }
    },
  },
};
</script>

<style>
.opacityHide {
  display: none !important;
}
.footerhide {
  animation: footerhide 1.5s ease-out forwards;
}
@keyframes footerhide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.footershow {
  animation: footershow 1.5s ease-out forwards;
}
@keyframes footershow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.footerText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: "Inter";
}
.footerImg {
  width: 114px;
  height: auto;
  margin-right: 10px;
}
.footerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
}
.line-with-shadows {
  position: absolute;
  width: 32%; /* 或其他固定宽度 */
  height: 50px; /* 根据需要调整，以容纳线条和投影 */
  overflow: hidden;
  display: flex;
  align-items: center;
  scale: 20%;
  left: 35%;
  top: 49%;
  transform: rotate(-17deg);
  opacity: 0;
  border-radius: 20%;
}

.page2Appearline {
  animation: page2Appearline 1.5s ease-out forwards;
}

@keyframes page2Appearline {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    scale: 20%;
    opacity: 0;
  }
  100% {
    scale: 150%;
    opacity: 1;
  }
}

.page3linePlayBack {
  animation: page3linePlayBack 1.5s ease-out forwards;
}

@keyframes page3linePlayBack {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    scale: 150%;
    opacity: 1;
  }
  100% {
    scale: 20%;
    opacity: 0;
  }
}

.page3linesHide {
  animation: page3linesHide 1.5s ease-out forwards;
}
@keyframes page3linesHide {
  0% {
    top: 49%;
    opacity: 1;
    scale: 150%;
  }
  100% {
    top: -10%;
    opacity: 0;
    scale: 150%;
  }
}

.top-shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 11px;
  height: 10px; /* 投影的高度 */
  background: #fbd784;
  opacity: 0.1;
  filter: blur(4px); /* 添加模糊效果 */
}
.bottom-shadow {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  height: 10px; /* 投影的高度 */
  background: #fbd784;
  opacity: 0.1;
  filter: blur(4px); /* 添加模糊效果 */
}
.leftCircle {
  height: 15px;
  width: 15px;
  background-color: #fbd784;
  border-radius: 50%;
  margin-right: 2px;
}
.rightCircle {
  height: 15px;
  width: 15px;
  background-color: #fbd784;
  border-radius: 50%;
  margin-left: auto;
}

.dashed-line {
  position: absolute;
  top: 50%; /* 将线条置于中间，考虑到投影的高度 */
  transform: translateY(-50%); /* 垂直居中线条 */
  left: 0;
  right: 0; /* 或具体宽度，根据需要 */
  height: 2px; /* 线条粗细 */
  background: repeating-linear-gradient(
    to right,
    #fbd784 0,
    #fbd784 4px,
    transparent 1px,
    transparent 17px
  ); /* 创建虚线效果 */
}

.page8 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 1.5vw;
  position: absolute;
  left: 26%;
  width: 50%;
  text-align: center;
  bottom: -30%;
  height: 100%;
  color: white;
  top: 5%;
}
.page8text {
  margin-bottom: 50px;
  bottom: -70%;
  position: absolute;
  font-family: "Georgia";
}
.page8line {
  position: absolute;
  bottom: -70%;
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
}
.page8-text {
  animation: page8Textappear 1.5s ease-out forwards;
}
@keyframes page8Textappear {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 50%;
    opacity: 1;
  }
}
.page8TextPlayBack {
  animation: page8TextPlayBack 1.5s ease-out forwards;
}
@keyframes page8TextPlayBack {
  0% {
    bottom: 50%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}
.page8-line {
  animation: page8Lineappear 1.5s ease-out forwards;
}
@keyframes page8Lineappear {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 50%;
    opacity: 1;
  }
}
.page8LinePlayBack {
  animation: page8LinePlayBack 1.5s ease-out forwards;
}
@keyframes page8LinePlayBack {
  0% {
    bottom: 50%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}
.page7 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  position: absolute;
  top: 10%;
}
.page7-hide {
  animation: page7Hide 1.5s ease-out forwards;
}
@keyframes page7Hide {
  0% {
    top: 10%;
    opacity: 1;
  }
  100% {
    top: -70%;
    opacity: 0;
  }
}
.page7HidePlayBack {
  animation: page7HidePlayBack 1.5s ease-out forwards;
}
@keyframes page7HidePlayBack {
  0% {
    top: -70%;
    opacity: 0;
  }
  100% {
    top: 10%;
    opacity: 1;
  }
}
.page7Text {
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 5%;
  padding-left: 5%;
}
.page7Imgs {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.no4 {
  font-size: 9.8vw;
  font-weight: bold;
  font-family: "Inter";
  position: absolute;
  right: 38%;
  color: rgb(28, 28, 28);
  z-index: 0;
  bottom: -20%;
}

.appear-page7no1 {
  animation: appearpage7no1 1.5s ease-out forwards;
}
@keyframes appearpage7no1 {
  0% {
    bottom: -20%;
    opacity: 0;
  }
  100% {
    bottom: 70%;
    opacity: 1;
  }
}
.appearPage7no1PlayBack {
  animation: appearPage7no1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7no1PlayBack {
  0% {
    bottom: 70%;
    opacity: 1;
  }
  100% {
    bottom: -20%;
    opacity: 0;
  }
}

.appear-page7firstLine {
  animation: page7firstLine 1.5s ease-out forwards;
}
@keyframes page7firstLine {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 80%;
    opacity: 1;
  }
}
.appearPage7firstLinePlayBack {
  animation: appearPage7firstLinePlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7firstLinePlayBack {
  0% {
    bottom: 80%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}
/* 超过1400px,笔记本 */
@media (min-width: 1400px) {
  .appear-page7secondLine {
    animation: page7secondLine1 1.5s ease-out forwards;
  }
  .appearPage7secondLinePlayBack {
    animation: appearPage7secondLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page7thirdLine {
    animation: page7thirdLine1 1.5s ease-out forwards;
  }
  .appearPage7thirdLinePlayBack {
    animation: appearPage7thirdLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page7fouthLine {
    animation: page7fouthLine1 1.5s ease-out forwards;
  }
  .appearPage7fouthLinePlayBack {
    animation: appearPage7fouthLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page7fifthLine {
    animation: appearPage7fifthLine1 1.5s ease-out forwards;
  }
  .appearPage7fifthLinePlayBack {
    animation: appearPage7fifthLinePlayBack1 1.5s ease-out forwards;
  }
}

@keyframes page7secondLine1 {
  0% {
    bottom: -50%;
    opacity: 0;
  }
  100% {
    bottom: 66%;
    opacity: 1;
  }
}

@keyframes appearPage7secondLinePlayBack1 {
  0% {
    bottom: 66%;
    opacity: 1;
  }
  100% {
    bottom: -52%;
    opacity: 0;
  }
}

@keyframes page7thirdLine1 {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 52%;
    opacity: 1;
  }
}

@keyframes appearPage7thirdLinePlayBack1 {
  0% {
    bottom: 52%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}

@keyframes page7fouthLine1 {
  0% {
    bottom: -90%;
    opacity: 0;
  }
  100% {
    bottom: 40%;
    opacity: 1;
  }
}

@keyframes appearPage7fouthLinePlayBack1 {
  0% {
    bottom: 40%;
    opacity: 1;
  }
  100% {
    bottom: -90%;
    opacity: 0;
  }
}

@keyframes appearPage7fifthLine1 {
  0% {
    bottom: -110%;
    opacity: 0;
  }
  100% {
    bottom: 26%;
    opacity: 1;
  }
}

@keyframes appearPage7fifthLinePlayBack1 {
  0% {
    bottom: 26%;
    opacity: 1;
  }
  100% {
    bottom: -110%;
    opacity: 0;
  }
}
@media (max-width: 1400px) {
  .appear-page7secondLine {
    animation: page7secondLine 1.5s ease-out forwards;
  }
  .appearPage7secondLinePlayBack {
    animation: appearPage7secondLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page7thirdLine {
    animation: page7thirdLine 1.5s ease-out forwards;
  }
  .appearPage7thirdLinePlayBack {
    animation: appearPage7thirdLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page7fouthLine {
    animation: page7fouthLine 1.5s ease-out forwards;
  }
  .appearPage7fouthLinePlayBack {
    animation: appearPage7fouthLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page7fifthLine {
    animation: appearPage7fifthLine 1.5s ease-out forwards;
  }
  .appearPage7fifthLinePlayBack {
    animation: appearPage7fifthLinePlayBack 1.5s ease-out forwards;
  }
}

@keyframes page7secondLine {
  0% {
    bottom: -50%;
    opacity: 0;
  }
  100% {
    bottom: 66%;
    opacity: 1;
  }
}

@keyframes appearPage7secondLinePlayBack {
  0% {
    bottom: 66%;
    opacity: 1;
  }
  100% {
    bottom: -52%;
    opacity: 0;
  }
}

@keyframes page7thirdLine {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 52%;
    opacity: 1;
  }
}

@keyframes appearPage7thirdLinePlayBack {
  0% {
    bottom: 52%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}

@keyframes page7fouthLine {
  0% {
    bottom: -90%;
    opacity: 0;
  }
  100% {
    bottom: 38%;
    opacity: 1;
  }
}

@keyframes appearPage7fouthLinePlayBack {
  0% {
    bottom: 38%;
    opacity: 1;
  }
  100% {
    bottom: -90%;
    opacity: 0;
  }
}

@keyframes appearPage7fifthLine {
  0% {
    bottom: -110%;
    opacity: 0;
  }
  100% {
    bottom: 25%;
    opacity: 1;
  }
}

@keyframes appearPage7fifthLinePlayBack {
  0% {
    bottom: 25%;
    opacity: 1;
  }
  100% {
    bottom: -110%;
    opacity: 0;
  }
}

.page7no1Line {
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
  margin-right: 30px;
}
.page7firstLine {
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: -30%;
  position: absolute;
}
.page7firstLineText {
  color: #fbd784;
  letter-spacing: 5px;
}

.page7secondLine {
  bottom: -50%;
  position: absolute;
  width: 30%;
  padding-bottom: 50px;
}

.page7thirdLine {
  bottom: -60%;
  position: absolute;
  width: 30%;
  padding-bottom: 50px;
}
.page7fouthLine {
  bottom: -70%;
  position: absolute;
  width: 30%;
  padding-bottom: 50px;
}
.page7fifthLine {
  bottom: -80%;
  position: absolute;
  padding-bottom: 50px;
  width: 30%;
}
.page7third1 {
  font-size: 33px;
  font-weight: bold;
  font-style: italic;
  font-family: "Georgia";
  margin-bottom: 10px;
}

.page7Img1 {
  width: 8%;
  height: auto;
  position: absolute;
  bottom: 30%;
  left: 52%;
  opacity: 0;
}
.page7Img2 {
  width: 8%;
  height: auto;
  position: absolute;
  bottom: 70%;
  left: 45%;
  opacity: 0;
}
.page7Img3 {
  width: 8%;
  height: auto;
  position: absolute;
  bottom: 70%;
  left: 60%;
  opacity: 0;
}
.page7Img4 {
  width: 8%;
  height: auto;
  position: absolute;
  bottom: 30%;
  left: 75%;
  opacity: 0;
}
.page7Img5 {
  width: 55%;
  height: auto;
  position: absolute;
  left: 25%;
  bottom: -150%;
}

.appear-page7img5 {
  animation: appearPage7Img5 1.5s ease-out forwards;
}

@keyframes appearPage7Img5 {
  0% {
    opacity: 0;
    bottom: -50%;
    left: 25%;
  }
  100% {
    bottom: 20%;
    opacity: 1;
    left: 25%;
  }
}
.appearPage7img5PlayBack {
  animation: appearPage7img5PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7img5PlayBack {
  0% {
    bottom: 20%;
    opacity: 1;
    left: 25%;
  }
  100% {
    opacity: 0;
    bottom: -50%;
    left: 25%;
  }
}
.appear-page7img1 {
  animation: appearPage7Img1 1.5s ease-out forwards;
}

@keyframes appearPage7Img1 {
  0% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
  100% {
    bottom: 60%;
    opacity: 1;
    left: 30%;
  }
}
.appearPage7img1PlayBack {
  animation: appearPage7img1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7img1PlayBack {
  0% {
    bottom: 60%;
    opacity: 1;
    left: 30%;
  }
  100% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
}
.appear-page7img4 {
  animation: appearPage7Img4 1.5s ease-out forwards;
}

@keyframes appearPage7Img4 {
  0% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
  100% {
    bottom: 60%;
    opacity: 1;
    left: 75%;
  }
}
.appearPage7img4PlayBack {
  animation: appearPage7img4PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7img4PlayBack {
  0% {
    bottom: 60%;
    opacity: 1;
    left: 75%;
  }
  100% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
}
.appear-page7img2 {
  animation: appearPage7Img2 1.5s ease-out forwards;
}

@keyframes appearPage7Img2 {
  0% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
  100% {
    opacity: 1;
    bottom: 70%;
    left: 45%;
  }
}
.appearPage7img2PlayBack {
  animation: appearPage7img2PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7img2PlayBack {
  0% {
    opacity: 1;
    bottom: 70%;
    left: 45%;
  }
  100% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
}
.appear-page7img3 {
  animation: appearPage7Img3 1.5s ease-out forwards;
}

@keyframes appearPage7Img3 {
  0% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
  100% {
    opacity: 1;
    bottom: 70%;
    left: 60%;
  }
}
.appearPage7img3PlayBack {
  animation: appearPage7img3PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage7img3PlayBack {
  0% {
    opacity: 1;
    bottom: 70%;
    left: 60%;
  }
  100% {
    opacity: 0;
    bottom: 30%;
    left: 52%;
  }
}

.page6 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  position: absolute;
  top: 10%;
}
.page6-hide {
  animation: page6Hide 1.5s ease-out forwards;
}
@keyframes page6Hide {
  0% {
    top: 10%;
    opacity: 1;
  }
  100% {
    top: -50%;
    opacity: 0;
  }
}
.page6HidePlayBack {
  animation: page6HidePlayBack 1.5s ease-out forwards;
}
@keyframes page6HidePlayBack {
  0% {
    top: -50%;
    opacity: 0;
  }
  100% {
    top: 10%;
    opacity: 1;
  }
}
.page6Text {
  width: 50%;
  padding-top: 17%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  padding-right: 5%;
  padding-left: 16%;
}
.page6Imgs {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  margin-right: 5%;
  position: relative;
  overflow: hidden;
}
.no3 {
  font-size: 9.8vw;
  font-weight: bold;
  font-family: "Inter";
  position: absolute;
  bottom: -30%;
  left: 12%;
  color: rgb(28, 28, 28);
  z-index: 0;
}
.appear-page6no1 {
  animation: appearpage4no1 1.5s ease-out forwards;
}
@keyframes appearpage6no1 {
  0% {
    bottom: -20%;
    opacity: 0;
  }
  100% {
    bottom: 70%;
    opacity: 1;
  }
}
.appearPage6no1PlayBack {
  animation: appearPage6no1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage6no1PlayBack {
  0% {
    bottom: 70%;
    opacity: 1;
  }
  100% {
    bottom: -20%;
    opacity: 0;
  }
}
.appear-page6firstLine {
  animation: page6firstLine 1.5s ease-out forwards;
}
@keyframes page6firstLine {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 80%;
    opacity: 1;
  }
}

.appearPage6firstLinePlayBack {
  animation: appearPage6firstLinePlayBack 1.5s ease-out forwards;
}
@keyframes appearPage6firstLinePlayBack {
  0% {
    bottom: 80%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}

.appear-page6thirdLine {
  animation: page6thirdLine 1.5s ease-out forwards;
}
@keyframes page6thirdLine {
  0% {
    bottom: -50%;
    opacity: 0;
  }
  100% {
    bottom: 66%;
    opacity: 1;
  }
}
.appearPage6thirdLinePlayBack {
  animation: appearPage6thirdLinePlayBack 1.5s ease-out forwards;
}
@keyframes appearPage6thirdLinePlayBack {
  0% {
    bottom: 66%;
    opacity: 1;
  }
  100% {
    bottom: -50%;
    opacity: 0;
  }
}
.appear-page6fourthLine {
  animation: page6fourthLine 1.5s ease-out forwards;
}
@keyframes page6fourthLine {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 52%;
    opacity: 1;
  }
}
.appearPage6fourthLinePlayBack {
  animation: appearPage6fourthLinePlayBack 1.5s ease-out forwards;
}
@keyframes appearPage6fourthLinePlayBack {
  0% {
    bottom: 52%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}
.appear-page6fifthLine {
  animation: page6fifthLine 1.5s ease-out forwards;
}
@keyframes page6fifthLine {
  0% {
    bottom: -90%;
    opacity: 0;
  }
  100% {
    bottom: 38%;
    opacity: 1;
  }
}
.appearPage6fifthLinePlayBack {
  animation: appearPage6fifthLinePlayBack 1.5s ease-out forwards;
}
@keyframes appearPage6fifthLinePlayBack {
  0% {
    bottom: 38%;
    opacity: 1;
  }
  100% {
    bottom: -90%;
    opacity: 0;
  }
}
.page6no1Line {
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
  margin-right: 30px;
}
.page6firstLine {
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: -30%;
  position: absolute;
}
.page6firstLineText {
  color: #fbd784;
  letter-spacing: 5px;
}
.page6thirdLine {
  bottom: -50%;
  position: absolute;
  width: 35%;
}
.page6fourthLine {
  bottom: -60%;
  position: absolute;
  width: 35%;
}
.page6fifthLine {
  bottom: -70%;
  position: absolute;
  width: 30%;
}
.page6third1 {
  font-size: 33px;
  font-weight: bold;
  font-style: italic;
  font-family: "Georgia";
}
.page6Img1 {
  width: 50%;
  height: auto;
  position: absolute;
  bottom: -40%;
}
.page6Img2 {
  width: 50%;
  height: auto;
  position: absolute;
  right: 16%;
  bottom: -120%;
}
.page6Img3 {
  width: 15%;
  height: auto;
  position: absolute;
  bottom: -120%;
}

.appear-page6img1 {
  animation: appearPage6Img1 1.5s ease-out forwards;
}

@keyframes appearPage6Img1 {
  0% {
    bottom: -40%;
    opacity: 0;
  }
  100% {
    bottom: 50%;
    opacity: 1;
  }
}
.appearPage6img1PlayBack {
  animation: appearPage6img1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage6img1PlayBack {
  0% {
    bottom: 50%;
    opacity: 1;
  }
  100% {
    bottom: -40%;
    opacity: 0;
  }
}
.appear-page6img2 {
  animation: appearPage6Img2 1.5s ease-out forwards;
}
.appearPage6img2PlayBack {
  animation: appearPage6img2PlayBack 1.5s ease-out forwards;
}

@keyframes appearPage6img2PlayBack {
  0% {
    bottom: 29%;
    opacity: 1;
  }
  100% {
    bottom: -120%;
    opacity: 0;
  }
}
.appear-page6img3 {
  animation: appearPage6Img3 1.5s ease-out forwards;
}
@keyframes appearPage6Img3 {
  0% {
    bottom: -120%;
    opacity: 0;
  }
  100% {
    bottom: 55%;
    opacity: 1;
  }
}
.appearPage6img3PlayBak {
  animation: appearPage6img3PlayBak 1.5s ease-out forwards;
}
@keyframes appearPage6img3PlayBak {
  0% {
    bottom: 55%;
    opacity: 1;
  }
  100% {
    bottom: -120%;
    opacity: 0;
  }
}

@keyframes appearPage6Img2 {
  0% {
    bottom: -120%;
    opacity: 0;
  }
  100% {
    bottom: 29%;
    opacity: 1;
  }
}

.page5 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  position: absolute;
  top: 10%;
}
.page5-hide {
  animation: page5Hide 1.5s ease-out forwards;
}
@keyframes page5Hide {
  0% {
    top: 10%;
    opacity: 1;
  }
  100% {
    top: -70%;
    opacity: 0;
  }
}
.page5HidePlayBack {
  animation: page5HidePlayBack 1.5s ease-out forwards;
}
@keyframes page5HidePlayBack {
  0% {
    top: -70%;
    opacity: 0;
  }
  100% {
    top: 10%;
    opacity: 1;
  }
}
.page5Text {
  width: 50%;
  padding-top: 17%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 8%;
  box-sizing: border-box;
  padding-right: 5%;
  padding-left: 5%;
}
.page5Imgs {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.no2 {
  font-size: 9.8vw;
  font-weight: bold;
  font-family: "Inter";
  position: absolute;
  right: 38%;
  color: rgb(28, 28, 28);
  z-index: 0;
  bottom: -30%;
}
.no2End {
  font-size: 9.8vw;
  font-weight: bold;
  font-family: "Inter";
  position: absolute;
  right: 38%;
  color: rgb(28, 28, 28);
  z-index: 0;
  bottom: 70%;
}

.appear-page5no1 {
  animation: appearpage5no1 1.5s ease-out forwards;
}
@keyframes appearpage5no1 {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 70%;
    opacity: 1;
  }
}
.appearPage5no1PlayBack {
  animation: appearPage5no1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage5no1PlayBack {
  0% {
    bottom: 70%;
    opacity: 1;
  }
  100% {
    bottom: -20%;
    opacity: 0;
  }
}
/* 超过1400px,笔记本 */
@media (min-width: 1400px) {
  .appear-page5firstLine {
    animation: page5firstLine1 1.5s ease-out forwards;
  }
  .appearPage5firstLinePlayBack {
    animation: appearPage5firstLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page5thirdLine {
    animation: page5thirdLine1 1.5s ease-out forwards;
  }
  .appearPage5thirdLinePlayBack {
    animation: appearPage5thirdLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page5fourthLine {
    animation: page5fourthLine1 1.5s ease-out forwards;
  }
  .appearPage5fourthLinePlayBack {
    animation: appearPage5fourthLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page5fifthLine {
    animation: page5fifthLine1 1.5s ease-out forwards;
  }
  .appearPage5fifthLinePlayBack {
    animation: appearPage5fifthLinePlayBack1 1.5s ease-out forwards;
  }
}

@keyframes page5firstLine1 {
  0% {
    bottom: -20%;
    opacity: 0;
  }
  100% {
    bottom: 80%;
    opacity: 1;
  }
}

@keyframes appearPage5firstLinePlayBack1 {
  0% {
    bottom: 80%;
    opacity: 1;
  }
  100% {
    bottom: -20%;
    opacity: 0;
  }
}

@keyframes page5thirdLine1 {
  0% {
    bottom: -40%;
    opacity: 0;
  }
  100% {
    bottom: 66%;
    opacity: 1;
  }
}

@keyframes appearPage5thirdLinePlayBack1 {
  0% {
    bottom: 66%;
    opacity: 1;
  }
  100% {
    bottom: -40%;
    opacity: 0;
  }
}

@keyframes page5fourthLine1 {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 52%;
    opacity: 1;
  }
}

@keyframes appearPage5fourthLinePlayBack1 {
  0% {
    bottom: 52%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}

@keyframes page5fifthLine1 {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 38%;
    opacity: 1;
  }
}

@keyframes appearPage5fifthLinePlayBack1 {
  0% {
    bottom: 38%;
    opacity: 1;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}
/* 超过1400px,笔记本 */
@media (max-width: 1400px) {
  .appear-page5firstLine {
    animation: page5firstLine 1.5s ease-out forwards;
  }
  .appearPage5firstLinePlayBack {
    animation: appearPage5firstLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page5thirdLine {
    animation: page5thirdLine 1.5s ease-out forwards;
  }
  .appearPage5thirdLinePlayBack {
    animation: appearPage5thirdLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page5fourthLine {
    animation: page5fourthLine 1.5s ease-out forwards;
  }
  .appearPage5fourthLinePlayBack {
    animation: appearPage5fourthLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page5fifthLine {
    animation: page5fifthLine 1.5s ease-out forwards;
  }
  .appearPage5fifthLinePlayBack {
    animation: appearPage5fifthLinePlayBack 1.5s ease-out forwards;
  }
}

@keyframes page5firstLine {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 80%;
    opacity: 1;
  }
}

@keyframes appearPage5firstLinePlayBack {
  0% {
    bottom: 80%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}

@keyframes page5thirdLine {
  0% {
    bottom: -50%;
    opacity: 0;
  }
  100% {
    bottom: 66%;
    opacity: 1;
  }
}

@keyframes appearPage5thirdLinePlayBack {
  0% {
    bottom: 66%;
    opacity: 1;
  }
  100% {
    bottom: -50%;
    opacity: 0;
  }
}

@keyframes page5fourthLine {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 52%;
    opacity: 1;
  }
}

@keyframes appearPage5fourthLinePlayBack {
  0% {
    bottom: 52%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}

@keyframes page5fifthLine {
  0% {
    bottom: -90%;
    opacity: 0;
  }
  100% {
    bottom: 38%;
    opacity: 1;
  }
}

@keyframes appearPage5fifthLinePlayBack {
  0% {
    bottom: 38%;
    opacity: 1;
  }
  100% {
    bottom: -90%;
    opacity: 0;
  }
}

.page5no1Line {
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
  margin-right: 30px;
}

.page5firstLine {
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: -30%;
  position: absolute;
}
.page5firstLineEnd {
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: 80%;
  position: absolute;
  opacity: 1;
}
.page5firstLineText {
  color: #fbd784;
  letter-spacing: 5px;
}

.page5thirdLine {
  bottom: -50%;
  position: absolute;
  width: 32%;
}
.page5thirdLineEnd {
  bottom: 66%;
  position: absolute;
  width: 32%;
}
.page5fourthLine {
  bottom: -60%;
  position: absolute;
  width: 32%;
}
.page5fourthLineEnd {
  bottom: 52%;
  position: absolute;
  width: 32%;
}
.page5fifthLine {
  bottom: -70%;
  position: absolute;
  width: 32%;
}
.page5fifthLineEnd {
  bottom: 38%;
  position: absolute;
  width: 32%;
}
.page5third1 {
  font-size: 33px;
  font-weight: bold;
  font-style: italic;
  font-family: "Georgia";
}
.page5Img1 {
  width: 26%;
  height: auto;
  position: absolute;
  bottom: -30%;
}
.page5Img1End {
  width: 26%;
  height: auto;
  position: absolute;
  bottom: 60%;
}
.page5Img2 {
  width: 55%;
  height: auto;
  position: absolute;
  bottom: -100%;
}
.page5Img2End {
  width: 55%;
  height: auto;
  position: absolute;
  bottom: 25%;
}
.appear-page5img1 {
  animation: appearPage5Img1 1.5s ease-out forwards;
}

@keyframes appearPage5Img1 {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 60%;
    opacity: 1;
  }
}
.appearPage5img1PlayBack {
  animation: appearPage5img1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage5img1PlayBack {
  0% {
    bottom: 60%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}
.appear-page5img2 {
  animation: appearPage5Img2 1.5s ease-out forwards;
}

@keyframes appearPage5Img2 {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 25%;
    opacity: 1;
  }
}
.appearPage5img2PlayBack {
  animation: appearPage5img2PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage5img2PlayBack {
  0% {
    bottom: 25%;
    opacity: 1;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}

.page4 {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  position: absolute;
  top: 10%;
}
.page4-hide {
  animation: page4Hide 1.5s ease-out forwards;
}
@keyframes page4Hide {
  0% {
    top: 10%;
    opacity: 1;
  }
  100% {
    top: -50%;
    opacity: 0;
  }
}
.page4HidePlayBack {
  animation: page4HidePlayBack 1.5s ease-out forwards;
}
@keyframes page4HidePlayBack {
  0% {
    top: -50%;
    opacity: 0;
  }
  100% {
    top: 10%;
    opacity: 1;
  }
}

.page4Text {
  width: 50%;
  padding-top: 17%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  padding-right: 5%;
  padding-left: 16%;
}

.page4Imgs {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  margin-right: 5%;
  position: relative;
  overflow: hidden;
}
.no1 {
  font-size: 9.8vw;
  font-weight: bold;
  font-family: "Inter";
  position: absolute;
  bottom: -30%;
  left: 12%;
  color: rgb(28, 28, 28);
  z-index: 0;
}
.appear-page4no1 {
  animation: appearpage4no1 1.5s ease-out forwards;
}
@keyframes appearpage4no1 {
  0% {
    bottom: -20%;
    opacity: 0;
  }
  100% {
    bottom: 70%;
    opacity: 1;
  }
}
.appearPage4no1PlayBack {
  animation: appearPage4no1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage4no1PlayBack {
  0% {
    bottom: 70%;
    opacity: 1;
  }
  100% {
    bottom: -20%;
    opacity: 0;
  }
}

.appear-page4firstLine {
  animation: page4firstLine 1.5s ease-out forwards;
}

@keyframes page4firstLine {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 80%;
    opacity: 1;
  }
}

.appearPage4firstLinePlayBack {
  animation: appearPage4firstLinePlayBack 1.5s ease-out forwards;
}
@keyframes appearPage4firstLinePlayBack {
  0% {
    bottom: 80%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}

/* 超过1400px,笔记本 */
@media (min-width: 1400px) {
  .appear-page4secLine {
    animation: page4secLine1 1.5s ease-out forwards;
  }
  .appearPage4secLinePlayBack {
    animation: appearPage4secLinePlayBack1 1.5s ease-out forwards;
  }
  .appear-page4thirdLine {
    animation: page4thirdLine1 1.5s ease-out forwards;
  }
  .appearPage4thirdLinepalyBack {
    animation: appearPage4thirdLinepalyBack1 1.5s ease-out forwards;
  }
  .appear-page4fourthLine {
    animation: page4fourthLine1 1.5s ease-out forwards;
  }
  .appearPage4fourthLinePalyBack {
    animation: appearPage4fourthLinePalyBack1 1.5s ease-out forwards;
  }
  .appear-page4fifthLine {
    animation: appearPage4fifthLine1 1.5s ease-out forwards;
  }
  .appearPage4fifthLinePalyBack {
    animation: appearPage4fifthLinePalyBack1 1.5s ease-out forwards;
  }
}

@keyframes page4secLine1 {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    bottom: -40%;
    opacity: 0;
  }
  100% {
    bottom: 70%;
    opacity: 1;
  }
}
@keyframes appearPage4secLinePlayBack1 {
  0% {
    bottom: 70%;
    opacity: 1;
  }
  100% {
    bottom: -40%;
    opacity: 0;
  }
}
@keyframes page4thirdLine1 {
  0% {
    bottom: -70%;
    opacity: 0;
  }
  100% {
    bottom: 58%;
    opacity: 1;
  }
}
@keyframes appearPage4thirdLinepalyBack1 {
  0% {
    bottom: 58%;
    opacity: 1;
  }
  100% {
    bottom: -70%;
    opacity: 0;
  }
}

@keyframes page4fourthLine1 {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 46%;
    opacity: 1;
  }
}

@keyframes appearPage4fourthLinePalyBack1 {
  0% {
    bottom: 46%;
    opacity: 1;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}

@keyframes appearPage4fifthLine1 {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    bottom: -130%;
    opacity: 0;
  }
  100% {
    bottom: 31%;
    opacity: 1;
  }
}

@keyframes appearPage4fifthLinePalyBack1 {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    bottom: 31%;
    opacity: 1;
  }
  100% {
    bottom: -130%;
    opacity: 0;
  }
}

/* 显示器 */
@media (max-width: 1400px) {
  .appear-page4secLine {
    animation: page4secLine 1.5s ease-out forwards;
  }
  .appearPage4secLinePlayBack {
    animation: appearPage4secLinePlayBack 1.5s ease-out forwards;
  }
  .appear-page4thirdLine {
    animation: page4thirdLine 1.5s ease-out forwards;
  }
  .appearPage4thirdLinepalyBack {
    animation: appearPage4thirdLinepalyBack 1.5s ease-out forwards;
  }
  .appear-page4fourthLine {
    animation: page4fourthLine 1.5s ease-out forwards;
  }
  .appearPage4fourthLinePalyBack {
    animation: appearPage4fourthLinePalyBack 1.5s ease-out forwards;
  }
  .appear-page4fifthLine {
    animation: appearPage4fifthLine 1.5s ease-out forwards;
  }
  .appearPage4fifthLinePalyBack {
    animation: appearPage4fifthLinePalyBack 1.5s ease-out forwards;
  }
}

@keyframes page4secLine {
  0% {
    bottom: -50%;
    opacity: 0;
  }
  100% {
    bottom: 66%;
    opacity: 1;
  }
}

@keyframes appearPage4secLinePlayBack {
  0% {
    bottom: 66%;
    opacity: 1;
  }
  100% {
    bottom: -50%;
    opacity: 0;
  }
}

@keyframes page4thirdLine {
  0% {
    bottom: -60%;
    opacity: 0;
  }
  100% {
    bottom: 52%;
    opacity: 1;
  }
}

@keyframes appearPage4thirdLinepalyBack {
  0% {
    bottom: 52%;
    opacity: 1;
  }
  100% {
    bottom: -60%;
    opacity: 0;
  }
}

@keyframes page4fourthLine {
  0% {
    bottom: -80%;
    opacity: 0;
  }
  100% {
    bottom: 38%;
    opacity: 1;
  }
}

@keyframes appearPage4fourthLinePalyBack {
  0% {
    bottom: 38%;
    opacity: 1;
  }
  100% {
    bottom: -80%;
    opacity: 0;
  }
}

@keyframes appearPage4fifthLine {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 25%;
    opacity: 1;
  }
}

@keyframes appearPage4fifthLinePalyBack {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    bottom: 25%;
    opacity: 1;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}
.no1Line {
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
  margin-right: 30px;
}
.firstLine {
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: -30%;
  position: absolute;
}
.firstLineText {
  color: #fbd784;
  letter-spacing: 5px;
}
.secLine {
  font-size: 22px;
  font-family: "Georgia";
  bottom: -40%;
  position: absolute;
  width: 30%;
}
.thirdLine {
  bottom: -50%;
  position: absolute;
}
.fourthLine {
  bottom: -60%;
  position: absolute;
}
.fifthLine {
  bottom: -70%;
  position: absolute;
  width: 30%;
}
.third1 {
  font-size: 33px;
  font-weight: bold;
  font-style: italic;
  font-family: "Georgia";
}
.page4Img1 {
  width: 50%;
  height: auto;
  position: absolute;
  bottom: -30%;
}
.page4Img2 {
  width: 50%;
  height: auto;
  position: absolute;
  bottom: -120%;
}

.appear-page4img1 {
  animation: appearPage4Img1 1.5s ease-out forwards;
}

@keyframes appearPage4Img1 {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 56%;
    opacity: 1;
  }
}
.appearPage4img1PlayBack {
  animation: appearPage4img1PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage4img1PlayBack {
  0% {
    bottom: 56%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}
.appear-page4img2 {
  animation: appearPage4Img2 1.5s ease-out forwards;
}

@keyframes appearPage4Img2 {
  0% {
    bottom: -120%;
    opacity: 0;
  }
  100% {
    bottom: 35%;
    opacity: 1;
  }
}
.appearPage4img2PlayBack {
  animation: appearPage4img2PlayBack 1.5s ease-out forwards;
}
@keyframes appearPage4img2PlayBack {
  0% {
    bottom: 35%;
    opacity: 1;
  }
  100% {
    bottom: -120%;
    opacity: 0;
  }
}
.contain {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  background-image: url("./assets/noise.svg");
  background-repeat: repeat;
  overflow: auto;
}

.content {
  width: 100%;
  display: flex;
}
/* 初始状态 */
.initLeftImg {
  position: absolute;
  bottom: -15%;
  z-index: 0;
  max-width: 55%;
  height: auto; /* 保持图片的原始宽高比 */
  opacity: 0.3;
  transform: rotate(-17deg);
  left: -8%;
}

.initLeftImgEnd {
  position: absolute;
  z-index: 0;
  max-width: 55%;
  height: auto; /* 保持图片的原始宽高比 */
  left: -8%;
  bottom: 15%;
  opacity: 1;
  transform: rotate(0deg);
}

.move-leftImg {
  animation: moveLeftImg 1.5s ease-out forwards;
}
/* 三屏缩小左手 */
.sacle-leftImg {
  animation: scaleLeftImg 1s ease-out forwards;
}

@keyframes scaleLeftImg {
  0% {
    max-width: 55%;
    bottom: 9%;
    opacity: 1;
    transform: rotate(0deg);
  }
  100% {
    bottom: 14%;
    opacity: 1;
    max-width: 32%;
  }
}
.sacleLeftImgPlayBack {
  animation: sacleLeftImgPlayBack 1s ease-out forwards;
}
@keyframes sacleLeftImgPlayBack {
  0% {
    bottom: 14%;
    opacity: 1;
    max-width: 32%;
  }
  100% {
    max-width: 55%;
    bottom: 9%;
    opacity: 1;
    transform: rotate(0deg);
  }
}
/* 三屏缩小右手 */
.sacle-rightImg {
  animation: scaleRightImgs 1.5s ease-out forwards;
}
@keyframes scaleRightImgs {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    max-width: 55%;
    top: 36%;
    opacity: 1;
    transform: rotate(0deg);
  }
  100% {
    top: 15%;
    opacity: 1;
    max-width: 32%;
    transform: rotate(-10deg);
  }
}
.sacleRightImgPlayBack {
  animation: sacleRightImgPlayBack 1.5s ease-out forwards;
}

@keyframes sacleRightImgPlayBack {
  0% {
    /* 动画开始时，元素在屏幕底部且完全透明 */
    top: 15%;
    opacity: 1;
    max-width: 32%;
    transform: rotate(-10deg);
  }
  100% {
    max-width: 55%;
    top: 36%;
    opacity: 1;
    transform: rotate(0deg);
  }
}

/* 四屏取消 */
.hide-leftImg {
  animation: hideLeftImg 1.5s ease-out forwards;
}

@keyframes hideLeftImg {
  0% {
    max-width: 32%;
    bottom: 15%;
    opacity: 1;
  }
  100% {
    bottom: 80%;
    opacity: 0;
    max-width: 32%;
  }
}
.hideLeftImgPlayBack {
  animation: hideLeftImgPlayBack 1.5s ease-out forwards;
}
@keyframes hideLeftImgPlayBack {
  0% {
    bottom: 80%;
    opacity: 0;
    max-width: 32%;
  }
  100% {
    max-width: 32%;
    bottom: 15%;
    opacity: 1;
  }
}
/* 四屏取消 */
.hide-rightImg {
  animation: hideRightImg 1.5s ease-out forwards;
}

@keyframes hideRightImg {
  0% {
    max-width: 32%;
    top: 15%;
    opacity: 1;
    transform: rotate(-10deg);
  }
  100% {
    top: -30%;
    opacity: 0;
    max-width: 32%;
    transform: rotate(-10deg);
  }
}
.hideRightImgPlayBack {
  animation: hideRightImgPlayBack 1.5s ease-out forwards;
}
@keyframes hideRightImgPlayBack {
  0% {
    top: -30%;
    opacity: 0;
    max-width: 32%;
    transform: rotate(-10deg);
  }
  100% {
    max-width: 32%;
    top: 15%;
    opacity: 1;
    transform: rotate(-10deg);
  }
}

/* 首屏左图片动画 */
@keyframes moveLeftImg {
  0% {
    bottom: -15%;
    opacity: 0.3;
    transform: rotate(-17deg);
  }
  100% {
    bottom: 9%;
    opacity: 1;
    transform: rotate(0deg);
  }
}

.move-rightImg {
  animation: moveRightImg 1.5s ease-out forwards;
}
/* 首屏右图片动画 */
@keyframes moveRightImg {
  0% {
    top: -6%;
    opacity: 0.3;
    transform: rotate(10deg);
  }
  100% {
    top: 36%;
    opacity: 1;
    transform: rotate(0deg);
  }
}
.initRightImg {
  position: absolute;
  top: -6%;
  z-index: 0;
  max-width: 57%;
  height: auto; /* 保持图片的原始宽高比 */
  opacity: 0.3;
  transform: rotate(10deg);
  right: -8%;
  z-index: 3;
}

.initRightImgEnd {
  position: absolute;
  z-index: 0;
  max-width: 57%;
  height: auto; /* 保持图片的原始宽高比 */
  right: -8%;
  z-index: 3;
  top: 25.7%;
  opacity: 1;
  transform: rotate(0deg);
}

.page2 {
  position: absolute;
  width: 62%;
  height: 50%;
  padding: 2%;
  padding-left: 6%;
  box-sizing: border-box;
  border-radius: 20px;
  border-bottom: 1px solid rgba(251, 215, 132, 0.1);
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.01)
  );
  backdrop-filter: blur(8px);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  background-image: url("./assets/Rectangle.svg");
  background-size: cover;
  background-image: "";
  display: flex;
  flex-direction: column;
  font-size: 20px;
  z-index: 2;
  bottom: -60%;
  left: 19%;
}
.page2Appear {
  animation: slideUpFadeIn 1.5s ease-out forwards;
}
/* 二屏开始动画 */
@keyframes slideUpFadeIn {
  0% {
    bottom: -60%;
    opacity: 0;
    display: none;
  }
  100% {
    bottom: 27%;
    opacity: 1;
    display: flex;
  }
}
/* 二屏隐藏动画 */
.fade-in {
  animation: fadeIn 1.5s ease-out forwards;
}
/* 二屏回放 */
.cardPlayBack {
  animation: cardPlayBack 1.5s ease-out forwards;
}

@keyframes cardPlayBack {
  0% {
    bottom: 100%;
    opacity: 0;
  }
  100% {
    bottom: 27%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    bottom: 27%;
    opacity: 1;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}
.page3 {
  position: absolute;
  color: white;
  width: 50%;
  left: 23%;
  font-size: 1.5vw;
  font-family: "Georgia";
  line-height: 40px;
  bottom: -40%;
  z-index: 5;
  text-align: center;
}
/* 三屏显示动画 */
.page3Appear {
  animation: page3Appear 1.5s ease-out forwards;
}
@keyframes page3Appear {
  0% {
    bottom: -40%;
    opacity: 0;
  }
  100% {
    bottom: 45%;
    opacity: 1;
  }
}
.page3PlayBack {
  animation: page3PlayBack 1.5s ease-out forwards;
}
@keyframes page3PlayBack {
  0% {
    bottom: 45%;
    opacity: 1;
  }
  100% {
    bottom: -40%;
    opacity: 0;
  }
}
/* 三屏隐藏 */
.page3Hide {
  animation: page3HideAni 1.5s ease-out forwards;
}
@keyframes page3HideAni {
  0% {
    bottom: 45%;
    opacity: 1;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}
.page3HidePlayBack {
  animation: page3HidePlayBack 1.5s ease-out forwards;
}
@keyframes page3HidePlayBack {
  0% {
    bottom: 100%;
    opacity: 0;
  }
  100% {
    bottom: 45%;
    opacity: 1;
  }
}
.title {
  color: #fbd784;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
}
.line {
  width: 100px;
  height: 3px;
  background-color: #fbd784;
  border-radius: 20px;
  margin-right: 30px;
}
.text {
  font-size: 4.73vw;
  color: white;
  font-weight: bold;
  font-style: italic;
  font-family: "Georgia";
  margin-top: 15px;
}
.next {
  font-size: 20px;
  color: white;
  margin-top: 40px;
  display: flex;
  align-items: center;
}
</style>
